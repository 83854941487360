import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { Link } from "gatsby"

import UniversalLink from "../../components/UniversalLink"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';


import { GatsbyImage, getImage } from "gatsby-plugin-image"


var decodeHtmlEntity = function(str) {
  return str.replace(/&#(\d+);/g, function(match, dec) {
    return String.fromCharCode(dec);
  });
};




// import DOMPurify from 'dompurify';

const Post = ({ pageContext }) => {
  const {
    post: { title, content, featuredImage, tags },
    next,
    prev,
    nextTitle,
    prevTitle,
    post,
    seo
  } = pageContext

  const posts  = pageContext.postSearchData.posts
  const options = pageContext.postSearchData.options
  
  
// const metaTitle = DOMPurify.sanitize(title);

console.log("featuredImage.node.sourceUrl", )
  return (
    <Layout posts={posts} engine={options}>
      
      <Seo title={seo.title}
           image={ "https://marcogomesweb.com" + featuredImage.node.imageFile['childImageSharp']['gatsbyImageData'].images.fallback.src}
           imageWidth={featuredImage.node.imageFile.childImageSharp.gatsbyImageData.width}
           imageHeight={featuredImage.node.imageFile.childImageSharp.gatsbyImageData.height}
           description={seo.metaDesc}
           keyword={seo.metaKeywords}
           siteUrl={seo.opengraphUrl}
           
      />

      

      <div className="sticky__shares container">
        <div className="sticky__shares__container">
              <ul className="sticky__shares__ul">
                <li className="sticky__shares__ul__li">
                  <UniversalLink to={`https://twitter.com/share?text=${decodeHtmlEntity(title)}&url=https://marcogomesweb.com${post.uri}&amp;via=marcogomesweb`} title="Share on Twitter"  className="share-btn">
                    <FontAwesomeIcon icon={faTwitter} />
                  </UniversalLink>
                </li>
                <li className="sticky__shares__ul__li">
                  <UniversalLink to={`https://www.facebook.com/sharer/sharer.php?u=${seo.opengraphUrl}`} title="Share on Facebook" rel="noreferrer" target="_parent" className="share-btn">
                      <FontAwesomeIcon icon={faFacebookF} />
                  </UniversalLink>
                </li>
              </ul>
        </div>
    </div>
  
     { post.acf_bitly.url === null 
      ?

        <div className="banner text-center">
          <div className="banner__link">
            <GatsbyImage image={ getImage( featuredImage.node.imageFile )} className=" imgborder"/>
          </div>
        </div>
      :
        <div className="banner text-center">
          <a className="banner__link" href={post.acf_bitly.url} rel="noopener noreferrer" target="_blank" >
            <GatsbyImage image={ getImage( featuredImage.node.imageFile )} alt={featuredImage.node.altText } className="imgborder" />
          </a>
        </div>
      }
      <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
      
      <div className="tag">
        { tags.nodes.map( tag => (
          <Link to={`/tag/${tag.slug}`} key={tag.id} className="tag__link">
            {tag.name}
          </Link> 
          ))
        }
      </div>
      <div dangerouslySetInnerHTML={{ __html: content }}/>

      <nav className={`pagination  ${prev ? "pagination--next" : "pagination--prev"} `}>
        
        {prev && 
            <Link to={prev} rel="prev" className="pagination__prev">
              <span className="pagination__prev__arrow">
                <FontAwesomeIcon icon={faArrowLeft} />
                {` previous article`}
              </span>
              <span className="pagination__title" dangerouslySetInnerHTML={{ __html: prevTitle}} ></span>
            </Link>
          
        }

        {next &&          
          
            <Link to={next} rel="next" className="pagination__next">
              <span className="pagination__next__arrow">
                {`next article `}
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
              <span dangerouslySetInnerHTML={{ __html: nextTitle}} className="pagination__title"></span>
              
            </Link>
                    
        }
      </nav>
    </Layout>
    
  )
}

export default Post